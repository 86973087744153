import React from 'react'
import NavBar from '../components/NavBar';
import HomeCotent from '../components/HomeCotent';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <div>
        <div className='h-screen'>
            <NavBar />
            <HomeCotent/>
            <Footer />
        </div>
    </div>
  )
}

export default Home;