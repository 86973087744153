import React from 'react'
import NavBar from '../components/NavBar'
import ContactContent from '../components/ContactContent'
import Footer from '../components/Footer'

const Contact = () => {
  return (
    <div>
        <NavBar />
        <ContactContent />
        <Footer />
    </div>
  )
}

export default Contact