import React from 'react';
import Rec from '../assets/Rectangle.png';
import Rec2 from '../assets/Subtract.png';
import Rec3 from '../assets/Image.png';
import Rec4 from '../assets/About 2.png';
import Rec5 from '../assets/About3.png';


const AboutContent = () => {
  return (
    <div className='flex flex-col'>
        <div className='flex flex-col bg-[#362711]/50 py-10 items-center lg:h-[72rem] h-[77rem] gap-6 lg:gap-16 relative'>
            <span className='absolute top-0'><img className='w-screen lg:h-[72rem] h-[77rem]' src={Rec4} alt=''></img></span>
            <div className='flex lg:flex-row flex-col-reverse lg:gap-16 gap-3'>
                <span className='flex  z-10 lg:mt-16 px-4 lg:px-0'>
                    <span className=''><img className='lg:w-[28rem] lg:h-[26rem] w-[20rem] h-[20rem]' src={Rec} alt=''></img></span>
                    <span className='-ml-28 mt-24'><img className='w-52 lg:h-60 h-40' src={Rec2} alt=''></img></span>
                </span>
                <span className='flex flex-col lg:gap-5 gap-2 z-10 lg:py-10 lg:px-0 px-4'>
                    <span className='lg:text-3xl text-xl font-semibold text-[#DD8A03]'>Profile History</span>
                    <span className='flex flex-col gap-2 text-white lg:w-[33rem] lg:text-start text-justify'>
                        <span className='text-base'>Trucrete Solutions Limited is dynamic ConstructionManagement firm with expertise in Infrastructural Development, Engineering Services, Procurement, Facility Management, and General Contracting.</span>
                        <span className='text-base'>With several groundbreaking engineering projects in our portfolio over the years, Trucrete is proud to have been involved in shaping Nigeria by elevating critical infrastructure to international standards, erecting buildings, bridges, flyovers and roads in key strategic locations in Nigeria. We have helped create a safer and more pleasant environment, all while encouraging state growth and development.</span>
                        <span className='text-base'>Our fleet of plant machinery and equipment gives us the capacity to successfully undertake multiple projects in various parts of Nigeria at any one time, while our network of tried and tested subcontractors and suppliers throughout the country and globally, enables us to procure the best resources for every project.</span>
                    </span>
                </span>
            </div>
            <span className='flex flex-col lg:gap-5 gap-1 z-10 px-4 lg:px-0'>
                <span className='lg:text-3xl text-xl font-semibold text-[#DD8A03]'>Organization Structure</span>
                <img src={Rec5} className=' lg:w-[55rem] wfit rounded-lg' alt=''></img>
            </span>
        </div>
        <div className='flex flex-col bg-[#000000]/90 py-10 lg:px-28 px-4 gap-6'>
            <span className='flex lg:flex-row flex-col lg:justify-between gap-3 lg:gap-0'>
                <span className='flex flex-col lg:gap-3'>
                    <span className='lg:text-3xl text-xl font-medium text-[#DD8A03]'>Who We Are</span>
                    <span className='text-base text-white lg:w-[32rem]'>Trucrete Solutions is a dynamic general contracting, engineering, procurement, facility management, and construction management firm. We are dedicated to excellence in every project we undertake. With abilities in general contracting, construction management, engineering, procurement, facility management, Civil Works and design/build we are able to provide a complete package to clients and meet their construction needs both onshore and offshore.</span>
                </span>
                <span className='flex flex-col lg:gap-3'>
                    <span className='lg:text-3xl text-xl font-medium text-[#DD8A03]'>What We Assure </span>
                    <span className='text-base text-white lg:w-[28rem]'>We commit to assist you, our valued customer, to achieve a successful project completion with sincerity of purpose, integrity in our conduct, 100% in quality of service, commitment to safe construction practices, and on-time project delivery schedules.</span>
                </span>
            </span>
            <span className='w-full'><img className='w-full h-[15rem] lg:h-fit' src={Rec3} alt=''></img></span>
        </div>
    </div>
  )
}

export default AboutContent;