import React from 'react'
import NavBar from '../components/NavBar';
import ServicesContent from '../components/ServicesContent';
import Footer from '../components/Footer';

const Services = () => {
  return (
    <div>
        <NavBar />
        <ServicesContent />
        <Footer />
    </div>
  )
}

export default Services;