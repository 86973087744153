import React from "react";
import Home from "./pages/Home";
import { Routes, Route} from 'react-router-dom';
import About from "./pages/About";
import Services from "./pages/Services";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";


function App() {
  return (
    <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/about-us' element={<About />}></Route>
        <Route path='/services' element={<Services />}></Route>
        <Route path='/projects' element={<Projects />}></Route>
        <Route path='/contact' element={<Contact  />}></Route>
    </Routes>
  );
}

export default App;
