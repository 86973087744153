import React from 'react'
import NavBar from '../components/NavBar';
import ProjectsContent from '../components/ProjectsContent';
import Footer from '../components/Footer';

const Projects = () => {
  return (
    <div>
        <NavBar />
        <ProjectsContent />
        <Footer />
    </div>
  )
}

export default Projects;