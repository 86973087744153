import {React, useState} from 'react';
import Logo from '../assets/logo.png';
import { Link } from 'react-router-dom';
import {HiOutlineMenu} from 'react-icons/hi';
import {CgClose} from 'react-icons/cg';

const NavBar = () => {

  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
      setIsOpen(!isOpen);
    };

  return (
    <div className='flex w-screen lg:justify-center px-4 justify-between items-center lg:px-0 bg-[#362711]'>
        <span className='flex py-8 gap-8 items-center'>
            <span className=''><img className='lg:w-52 w-24' src={Logo} alt=''></img></span>
            <span className='lg:flex hidden gap-8 items-center'>
              <Link to='/'><span className='text-lg text-white hover:text-[#DD8A03]'>Home</span></Link>
              <Link to='/about-us'><span className='text-lg text-white hover:text-[#DD8A03]'>About Us</span></Link>
              <Link to='/services'><span className='text-lg text-white hover:text-[#DD8A03]'>Our Services</span></Link>
              <Link to='/projects'><span className='text-lg text-white hover:text-[#DD8A03]'>Our Projects</span></Link>
              <Link to='/contact'><span className='text-lg px-3 py-2 rounded-xl bg-[#794B02] text-white hover:text-[#DD8A03]'>
            Contact Us
            </span></Link>
            </span>
        </span>
        <span className='rounded p-1 h-fit flex lg:hidden items-center justify-center bg-[#794B02]' onClick={toggleMenu}>
          {isOpen ? <CgClose className='text-2xl text-white'/> : <HiOutlineMenu className='text-2xl text-white'/>} 
        </span>
        {
            isOpen && (
                <div className='absolute top-20 left-0 bg-[#DD8A03] rounded-b-md py-7 px-2 w-full z-50 flex flex-col text-start'>
                    <div className='flex flex-col px-2 gap-4 justify-center items-center'>
                      <Link to='/'><span className='text-lg text-white hover:text-[#DD8A03]'>Home</span></Link>
                      <Link to='/about-us'><span className='text-lg text-white hover:text-[#DD8A03]'>About Us</span></Link>
                      <Link to='/services'><span className='text-lg text-white hover:text-[#DD8A03]'>Our Services</span></Link>
                      <Link to='/projects'><span className='text-lg text-white hover:text-[#DD8A03]'>Our Projects</span></Link>
                      <Link to='/contact'><span className='text-base px-3 py-2 rounded-xl bg-[#794B02] text-white hover:text-[#DD8A03]'>
                        Contact Us
                        </span>
                      </Link>
                    </div>
                </div>
            )
        }
    </div>
  )
}

export default NavBar;